import './App.scss';
import { Route, Routes, useLocation } from 'react-router-dom';
import { lazy, Suspense, useEffect } from 'react';
import { useAppContext } from './AppContext';
import { Preloader } from './Preloader';

const Layout = lazy(() => import('./components/sections/Layout'));
const HomePageLazy = lazy(() => import('./components/sections/HomePageLazy'));
const PrivacyPolicy = lazy(() => import('./components/sections/PrivacyPolicy'));
const NotFound = lazy(() => import('./NotFound'));

function App() {
  const location = useLocation();
  const {theme, preLoading, setPreLoading} = useAppContext();


  return (
        <div className={`App ${theme === 'dark' ? "light": "dark"}`}>
          <Suspense fallback={<Preloader/>}>
        <Routes >
          <Route path="/" element={<Layout />} location={location} key={location.key} >
            <Route index element={<HomePageLazy />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />}  />
           {/* Catch-all route for 404 page */}
            <Route path="*" element={<NotFound />} />
          </Route>
        </Routes>
        </Suspense>
      </div>
  );
}

export default App;
