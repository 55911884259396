// src/Preloader.js
import React from 'react';
import './Preloader.scss';
import { Link } from 'react-router-dom';
import { useAppContext } from './AppContext';
import { Button1 } from './components/sections/Pages';

export const Preloader = () => {
    const companyName = "Oriental Media";
  
    return (
      <div className="preloader">
        <div className="preloader-text">
          {companyName.split("").map((letter, index) => (
            <h1
              key={index}
              className={`letter ${letter === " " ? "space" : ""}`}
              style={{ animationDelay: `${index * 0.1}s` }}
            >
              {letter}
            </h1>
          ))}
        </div>
      </div>
  );
};

