import React, { createContext, useContext, useState } from "react";

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  const [isOpen, setIsOpen] = useState(false);
  const [activeSection, setActiveSection] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [isContactFormSubmitted, setIsContactFormSubmitted] = useState(false);
  const [isContactFormError, setIsContactFormError] = useState(false);
  const [preLoading, setPreLoading] = useState(true);

  const toggleContactModal = () => {
    setIsModalOpen(!isModalOpen);
    setIsContactFormSubmitted(false);
    setIsContactFormError(false);
    console.log("modal opened");
  };

  return (
    <AppContext.Provider
      value={{
        theme,
        setTheme,
        isOpen,
        setIsOpen,
        activeSection,
        setActiveSection,
        isModalOpen,
        setIsModalOpen,
        toggleContactModal,
        successMessage,
        setSuccessMessage,
        isContactFormSubmitted,
        setIsContactFormSubmitted,
        isContactFormError,
        setIsContactFormError,
        preLoading,
        setPreLoading,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

// Custom hook to use the context
export const useAppContext = () => {
  const context = useContext(AppContext);
  if (!context) {
    throw new Error("useAppContext must be used within an AppProvider");
  }
  return context;
};
