import gsap from 'gsap';
import React, { useEffect, useRef, useState } from 'react'
import { FaArrowRight, FaPause, FaPlay } from 'react-icons/fa';

export const SectionOne = () => {
  return (
    <div className='hero-section'>
        <h1>Heading one</h1>
        <h2>Heading two</h2>
        <h3>Heading three</h3>
        <h4>Heading four</h4>
        <h5>Heading five</h5>
        <h6>Heading six</h6>
        <blockquote>Quote</blockquote>
        <Button1 btnlink='/#' bg='dark-bg' color='light-text' content='Get started'/>
        <Button2 btnlink='/#' bg='dark-bg' color='light-text' content='Get started'/>
        <Button3 btnlink='/#' bg='transparent' color='dark-text' content='Start today!'/>
        <Button4 btnlink='/#' bg='light-bg' color='dark-text' content='Get started'/>
        
        <span></span>
    </div>
  )
}

export const Button1 = ({btnlink, bg, color, content, type ='button', clickToggle = () => {}}) => {
  const handleButtonClick = (e) => {
    if (!btnlink) {
      e.preventDefault();
    }
    clickToggle();
  };
  return (
    <a href={btnlink} className='btn-a' onClick={handleButtonClick}>
        <button type={type} className={`button-one`} style={{'--color': `var(--${color})`, '--bcg': `var(--${bg})`}}>
            <span>{content}</span>
            <FaArrowRight />
        </button>
    </a>
  )
}
export const Button2 = ({btnlink, bg, color, content, clickToggle = () => {}}) => {
  const handleButtonClick = (e) => {
    if (!btnlink) {
      // Prevent the default behavior if `btnlink` is not provided
      e.preventDefault();
    }
    // Call the `clickToggle` function regardless
    clickToggle();
  };
  return (
    <a href={btnlink} className='btn-a' onClick={handleButtonClick}>
        <button type='button' className={`button-two`} style={{'--color': `var(--${color})`, '--bcg': `var(--${bg})`}}>
            <span>{content}</span>
            <FaArrowRight />
        </button>
    </a>
  )
}

export const Button3 = ({btnlink, bg, color, content, clickToggle = () => {}}) => {
  const handleButtonClick = (e) => {
    if (!btnlink) {
      // Prevent the default behavior if `btnlink` is not provided
      e.preventDefault();
    }
    // Call the `clickToggle` function regardless
    clickToggle();
  };
  return (
    <a href={btnlink} className='btn-a' >
        <button type='button' className={`button-three `} style={{'--color': `var(--${color})`, '--bcg': `var(--${bg})`}} onClick={handleButtonClick}>
            <span>{content}</span>
        </button>
    </a>
  )
}
export const Button4 = ({btnlink, bg, color, content, clickToggle = () => {}}) => {
  const handleButtonClick = (e) => {
    if (!btnlink) {
      e.preventDefault();
    }
    clickToggle();
  };

  return (
    <a href={btnlink} className='btn-a'>
        <button type='button' className={`button-four`} style={{'--color': `var(--${color})`, '--bcg': `var(--${bg})`}} onClick={handleButtonClick}>
            <span>{content}</span>
            <FaArrowRight />
        </button>
    </a>
  )
}
export const LinkButton = ({btnlink, bg, color, content, clickToggle = () => {}}) => {
  const handleButtonClick = (e) => {
    if (!btnlink) {
      // Prevent the default behavior if `btnlink` is not provided
      e.preventDefault();
      console.log('prevented')
    }
    // Call the `clickToggle` function regardless
    clickToggle();
  };

  return (
    <a href={btnlink} className={`link-button`} style={{'--color': `var(--${color})`, '--bcg': `var(--${bg})`}} onClick={handleButtonClick}>
        {content}
    </a>
  )
}


export const PrivacyPolicy = () => {
  return (
    <div>PrivacyPolicy</div>
  )
}


export const IconButton1 = ({btnlink, bg, color, content, children, }) => {
  return (
    <span className={`icon-button`} style={{'--color': `var(--${color})`, '--bcg': `var(--${bg})`}}>
           {children}
        </span>
  )
}

export const UnorderedList = ({listMap}) => {
  const styledListRef = useRef();

  // useEffect(() => {
  //   if (styledListRef.current){
  //     gsap.from(styledListRef.current, {
  //       opacity: 0,
  //       y: 20,
  //       duration: 0.6,
  //       stagger: 0.2,
  //       ease: "power3.out",
  //     });
  //   }
  // }, []);

  return (
    <ul className='styled-list' >
      {listMap.map((item, index) => (
        <li key={index} className='list-item' ref={(el) => (styledListRef[index] = el)}>{item}</li>
      ))}
    </ul>
  )
}


export const VideoComponent = ({thumbnail, video, alt='', bg}) => {
  const [isPlaying, setIsPlaying] = useState(false);
  const videoRef = useRef(null);

  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  return (
      <div className='video-media' >
      <video
        ref={videoRef}
        src={video} // Add your video source here
        poster={thumbnail} // Use the image as the video poster
        className={`video-player ${isPlaying ? '' : 'hide-video' }`}
        onEnded={() => setIsPlaying(false)} // Reset when video ends
      />
    <img alt={alt} src={thumbnail} className={`video-thumbnail ${isPlaying ? 'show-video' : '' }`} />
    <div className={`video-media-overlay ${isPlaying ? 'show-video' : '' }`}></div>
    <div className='video-mask' style={{'--video-icon-bg': bg}}>
      <div className='video-play' onClick={handlePlayPause}>
        {isPlaying ? <FaPause /> : <FaPlay />}
      </div>
    </div>
    
</div>
  )
}
